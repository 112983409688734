import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { StatusLabelType } from '@shared/components/new-design/status-label/status-label-type';
import { TransactionStatus } from '@shared/models/transactions/transaction-status';
import { smartCoinOrderStatusPipe } from '@shared/pipes/smart-coin-order-status.pipe';
import {
  SmartCoinOrderStatusInternal,
  SmartCoinOrderStatusExternal,
} from '@shared/types/smart-coin-order-status';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';

import { styles } from './status-label.styles';

export interface StatusLabelProps extends AppWithStyles<typeof styles> {
  status: SmartCoinOrderStatusInternal | TransactionStatus;
  type: StatusLabelType;
  className?: string;
}

const StatusLabelComponent: React.FC<StatusLabelProps> = (props) => {
  const { classes: c, status, type, className } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const externalStatus =
    type === StatusLabelType.ORDER
      ? smartCoinOrderStatusPipe(status as SmartCoinOrderStatusInternal)
      : status;

  let statusClass = '';
  let statusText = '';
  switch (externalStatus) {
    case TransactionStatus.InProgress:
    case SmartCoinOrderStatusExternal.IN_PROGRESS:
      statusClass = c.inProgress;
      statusText = t`In progress`;
      break;
    case TransactionStatus.Failed:
    case SmartCoinOrderStatusExternal.CANCELED:
      statusClass = c.canceled;
      statusText = t`Canceled`;
      break;
    case TransactionStatus.Completed:
    case SmartCoinOrderStatusExternal.EXECUTED:
      statusClass = c.executed;
      statusText = t`Successful`;
      break;
    case TransactionStatus.RequiresAction:
    case SmartCoinOrderStatusExternal.SECURE_3D:
      statusClass = c.inProgress;
      statusText = t`Requires 3DS`;
      break;
  }

  return isMobile ? (
    <div className={classNames(c.root, statusClass, className)}></div>
  ) : (
    <div className={classNames(c.root, className)}>
      <div className={classNames(c.icon, statusClass)}></div>
      <div className={c.text}>{statusText}</div>
    </div>
  );
};

export const StatusLabel = appWithStyles(styles)(StatusLabelComponent);
