import { AppTheme } from '@core/theme/types/main';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { Switch } from '@mui/material';
import { Layout } from '@shared/utils/layout';

export const AppSwitch = appWithStyles(({ palette, transitions, breakpoints }: AppTheme) => ({
  root: {
    width: 47,
    height: 29,
    borderRadius: '100px',
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(18px)',
        color: palette.common.white,
        '& + .MuiSwitch-track': {
          backgroundColor: '#7543FF',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        opacity: 0.7,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      color: '#fff',
      boxSizing: 'border-box',
      width: 25,
      height: 25,
      boxShadow:
        '0px 3px 1px 0px rgba(0, 0, 0, 0.06), 0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.04)',
    },
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
      backgroundColor: '#2F2F2F',
      opacity: 1,
      transition: transitions.create(['background-color'], {
        duration: 500,
      }),
      [breakpoints.up(Layout.laptop)]: {
        backgroundColor: '#473763',
      },
    },

    // Disabled
    '& .MuiSwitch-switchBase.Mui-disabled': {
      '& .MuiSwitch-thumb': {
        color: '#ffffff',
        opacity: '1 !important',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#2F2F2F',
        [breakpoints.up(Layout.laptop)]: {
          backgroundColor: '#473763',
        },
      },
    },
  },
}))(Switch);
