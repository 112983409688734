import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { ArrowRightButton } from '@shared/components/new-design/arrow-right-button/arrow-right-button';
import { DeleteButton } from '@shared/components/new-design/delete-button/arrow-right-button/delete-button';
import StarSVG from '@shared/components/new-design/images/star-white.svg';
import { MoreButton } from '@shared/components/new-design/more-button/more-button';
import amexIcon from '@shared/icons/cards/img/american-express.svg';
import discoverIcon from '@shared/icons/cards/img/discover.svg';
import jcbIcon from '@shared/icons/cards/img/jcb.svg';
import maestroIcon from '@shared/icons/cards/img/maestro.svg';
import mastercardIcon from '@shared/icons/cards/img/mastercard.svg';
import placeholderIcon from '@shared/icons/cards/img/placeholder.svg';
import visaColorIcon from '@shared/icons/cards/img/visa-color.svg';
import visaIcon from '@shared/icons/cards/img/visa.svg';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';

import { styles } from './card.styles';

export type CardProps = AppWithStyles<any> & {
  brand: string;
  lastDigits: string;
  isExpired: boolean;
  isActiveMoreButton?: boolean;
  isActiveArrowRightButton?: boolean;
  isDeletingMode?: boolean;
  onDelete?: () => void;
};

const CardComponent: React.FC<CardProps> = ({
  classes,
  brand,
  lastDigits,
  isActiveMoreButton,
  isActiveArrowRightButton,
  isDeletingMode,
  onDelete,
  isExpired,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  let svgIcon;
  switch (brand) {
    case 'visa':
      svgIcon = isMobile ? visaIcon : visaColorIcon;
      break;
    case 'mastercard':
      svgIcon = mastercardIcon;
      break;
    case 'amex':
      svgIcon = amexIcon;
      break;
    case 'jcb':
      svgIcon = jcbIcon;
      break;
    case 'maestro':
      svgIcon = maestroIcon;
      break;
    case 'discover':
      svgIcon = discoverIcon;
      break;
    default:
      svgIcon = placeholderIcon;
  }

  return (
    <div
      className={classNames(classes.root, {
        [classes.opacity as string]: isDeletingMode,
        [classes.expired as string]: isExpired,
        'card-root': true,
      })}
    >
      <div className={classes.info}>
        <div className={classes.iconBlock}>
          <img className={`${classes.icon} ${classes[brand]}`} src={String(svgIcon)} />
        </div>
        <div className={classes.divider}>/</div>
        <div className={classes.cardNumberBlock}>
          <div className={classes.cardNumberPlaceholder}>
            <div className={classes.digitsBlock}>
              <img alt={''} className={classes.star} src={String(StarSVG)} />
              <img alt={''} className={classes.star} src={String(StarSVG)} />
              <img alt={''} className={classes.star} src={String(StarSVG)} />
              <img alt={''} className={classes.star} src={String(StarSVG)} />
            </div>
            <div className={classes.digitsBlock}>
              <img alt={''} className={classes.star} src={String(StarSVG)} />
            </div>
          </div>
          <div className={classes.lastDigits}>{lastDigits}</div>
        </div>
      </div>
      {isActiveMoreButton ? (
        <div className={classes.menuIcon}>
          <MoreButton onClick={() => {}} />
        </div>
      ) : (
        <></>
      )}
      {isActiveArrowRightButton ? (
        <div className={classes.menuIcon}>
          <ArrowRightButton onClick={() => {}} />
        </div>
      ) : (
        <></>
      )}
      {isDeletingMode ? (
        <div className={classes.menuIcon}>
          <DeleteButton onClick={() => onDelete && onDelete()} />
        </div>
      ) : (
        <></>
      )}
      {isExpired ? <div className={classes.expiredText}>{t`Can't be used (expired)`}</div> : <></>}
    </div>
  );
};

export const Card = appWithStyles(styles)(CardComponent);
