import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export const styles = ({ breakpoints }: AppTheme) =>
  appCreateStyles({
    root: {},
    clickable: {
      cursor: 'pointer',
    },
    notClickable: {
      pointerEvents: 'none',
    },
    control: {
      position: 'relative',
    },
    input: {
      backgroundColor: _var(CSS_VARIABLES.backgroundEmptyColor),
    },
    active: {
      '& svg': {
        fill: `${_var(CSS_VARIABLES.accentColor)} !important`,
      },
    },
    icon: {
      position: 'absolute',
      left: '0',
      bottom: '0',
      top: '0',
      margin: 'auto 0',

      '& svg': {
        fill: '#141416',
      },

      [breakpoints.down(Layout.mobile)]: {
        left: '10px',
      },
    },
    instructions: {
      fontSize: '13px',
      lineHeight: '17px',
      fontWeight: 400,
      textAlign: 'center',
      textDecoration: 'underline',
      color: _var(CSS_VARIABLES.textSubTitlesColor),
    },
  });

export const StyledSelect = appWithStyles(() => ({
  root: {},
}))(Select);

export const StyledInput = appWithStyles(({ breakpoints }) => ({
  root: {
    height: '29px',
    '&:before': {
      border: 0,
    },
    '&:hover::before': {
      border: '0 !important',
    },
    '&:hover::after': {
      border: 0,
    },
  },
  focused: {
    backgroundColor: 'transparent',
    '&:before': {
      border: 0,
    },
    '&:after': {
      border: 0,
    },
  },

  disabled: {
    color: '#fff !important',
    '-webkit-text-fill-color': '#fff !important',
  },

  input: {
    [breakpoints.down(Layout.mobile)]: {
      width: 0,
      minWidth: 0,
      color: 'transparent',
    },

    //height: '29px',
    //padding: '0 30px 0 35px !important',
    fontSize: '11px',
    fontWeight: 600,
    color: '#FFF',
    textTransform: 'uppercase',
    letterSpacing: '0.033px',

    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}))(Input);
