import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { FormControl, MenuItem, SelectProps } from '@mui/material';
import classNames from 'classnames';

import { ArrowIcon } from './components/arrow-icon';

import { StyledInput, StyledSelect, styles } from './small-select.styles';

export type SmallSelectProps = AppWithStyles<typeof styles> &
  SelectProps & {
    options: { value: string | number; label: string }[];
    LeftIconComponent?: React.ElementType;
    IconComponent?: React.ElementType;
    active?: boolean;
    clickable?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  };

const SmallSelectComponent: React.FC<SmallSelectProps> = (props) => {
  const { classes, onChange, onClick, clickable, options, value, defaultValue, IconComponent } =
    props;
  const { error, LeftIconComponent, active, disabled } = props;
  const iconClasses = classNames(classes.icon, {
    [classes.active as string]: active,
    [classes.disabled as string]: disabled,
  });
  const rootClasses = classNames(classes.root, { [classes.clickable as string]: clickable });
  const controlClasses = classNames(classes.control, {
    [classes.notClickable as string]: clickable,
  });
  return (
    <div className={rootClasses} onClick={disabled ? undefined : onClick}>
      <FormControl disabled={disabled} className={controlClasses} error={error}>
        {LeftIconComponent && <LeftIconComponent className={iconClasses} />}
        <StyledSelect
          displayEmpty
          value={value}
          defaultValue={defaultValue}
          IconComponent={IconComponent || ArrowIcon}
          onChange={onChange}
          input={<StyledInput />}
        >
          {options.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export const SmallSelect = appWithStyles(styles)(SmallSelectComponent);
