import React, { useMemo } from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { Loading } from '@shared/components/loading';
import Persona from 'persona';
import { useMutation, useQuery } from 'react-query';

import { PersonaWidgetViewModel } from './persona-widget.vm';

import { styles } from './persona-widget.styles';

export type PersonaWidgetProps = AppWithStyles<any> & {
  onComplete: () => void;
  onError: (error: string) => void;
};

const PersonaWidgetComponent: React.FC<PersonaWidgetProps> = ({ classes, onComplete, onError }) => {
  const $vm = useMemo(() => new PersonaWidgetViewModel(), []);

  const downloadKYCCredentialsQuery = useQuery(
    ['download-kyc-creds'],
    () => $vm.downloadKYCCredentials(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      onSuccess: (data) => {
        if (data && data.errors && data.errors[0]) {
          onError(`${data.errors[0].key}: ${data.errors[0].text}`);
        }
      },
    },
  );

  const getMyUserDetailsQuery = useQuery(['get-my-user-details'], () => $vm.getMyUserDetails(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    refetchOnMount: true,
  });

  const submitKYCMutationMutation = useMutation(() => $vm.submitKYC(), {
    onSuccess: () => {
      onComplete();
    },
    onError: (error: Error) => {
      onError(error.message);
    },
    onSettled: () => {
      getMyUserDetailsQuery.refetch();
    },
  });

  if (downloadKYCCredentialsQuery.isFetching) {
    return (
      <div className={classes.root}>
        <Loading size={70} />
      </div>
    );
  } else {
    if (!$vm.inquiryId) {
      onError(`InquiryId is empty`);
      return <></>;
    }
  }
  const frameWidth = '100%';
  const frameHeight = '100%';
  return (
    <div className={classes.root}>
      <Persona.Inquiry
        inquiryId={$vm.inquiryId}
        sessionToken={$vm.sessionToken}
        frameWidth={frameWidth}
        frameHeight={frameHeight}
        onLoad={() => {}}
        onError={(error) => {
          $vm.removeCredentialsFromStorage();
          downloadKYCCredentialsQuery.refetch();
        }}
        onComplete={() => submitKYCMutationMutation.mutate()}
      />
    </div>
  );
};

export const PersonaWidget = appWithStyles(styles)(PersonaWidgetComponent);
