import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export const styles = (theme: Theme) =>
  appCreateStyles({
    root: {
      cursor: 'pointer',
      width: '100%',
      height: '67px',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 20px',
      borderRadius: '12px',
      border: '1px solid #7543FF',
      color: '#fff',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        border: 'none',
      },
    },
    choosen: {
      background: '#1E1D36',
      paddingLeft: '0px',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: 'transparent',
        border: `none`,
      },
    },
    empty: {
      backgroundColor: 'transparent',
      color: '#fff',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '160%',
      letterSpacing: '0.075px',
    },
    wallet: {
      backgroundColor: '#63608C',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: 'transparent',
      },
    },
    card: {
      backgroundColor: '#63608C',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: 'transparent',
      },
    },
    choosePaymentMethod: {},
    drawerContent: {
      maxHeight: '100%',
      overflow: 'auto',
    },
    desktopModalView: {
      '& .card-root': {
        backgroundColor: '#412D68',
      },
      '& .wallet-root': {
        backgroundColor: '#412D68',
      },
    },
    drawer: {
      '& .MuiPaper-root': {
        maxHeight: `calc(${_var(CSS_VARIABLES.appHeight)} - 100px)`,
        height: 'auto',
      },
    },
  });
