import React, { useEffect, useState } from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { useMediaQuery, Theme, Drawer, Modal, Box } from '@mui/material';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import { Card } from '@shared/components/new-design/select-payment-method/components/card/card';
import { Wallet } from '@shared/components/new-design/select-payment-method/components/wallet/wallet';
import { SelectedPaymentMethod } from '@shared/components/new-design/select-payment-method/selected-payment-method.type';
import { CreditCardListModel } from '@shared/models/credit-card/list-model';
import { MultiMetalPaymentType } from '@shared/models/orders/payment-method';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { amountPipe } from '@shared/pipes';
import { Layout } from '@shared/utils/layout';

import { PaymentMethodsMenu } from './components/payment-methods-menu';
import ArrowRightSvg from './img/arrow-right.svg';

import { styles } from './select-payment-method.styles';

export type SelectPaymentMethodProps = AppWithStyles<any> & {
  wallets: Array<WalletsListModel>;
  cards: Array<CreditCardListModel>;
  onSelectPaymentMethod: (selectedPaymentMethod: SelectedPaymentMethod) => void;
  onAddNewCard: () => void;
  toggleIsOpenPaymentMethod: (isOpen: boolean) => void;
  isOpenPaymentMethod: boolean;
  isPurchase?: boolean;
  defaultPaymentMethod?: SelectedPaymentMethod;
  description?: string;
  emptyStateText?: string;
  isHiddenCards?: boolean;
};

const SelectPaymentMethodComponent: React.FC<SelectPaymentMethodProps> = ({
  classes,
  wallets,
  cards,
  defaultPaymentMethod,
  onSelectPaymentMethod,
  isOpenPaymentMethod,
  toggleIsOpenPaymentMethod,
  onAddNewCard,
  isPurchase,
  description,
  emptyStateText,
  isHiddenCards,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<SelectedPaymentMethod | null>(
    null,
  );

  useEffect(() => {
    if (defaultPaymentMethod) {
      setSelectedPaymentMethod(defaultPaymentMethod);
    }
  }, [defaultPaymentMethod]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const handleSelectPaymentMethod = (paymentMethod: SelectedPaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    onSelectPaymentMethod(paymentMethod);
  };

  let currentPaymentComponent;

  const choosePaymentMethodComponent = isPurchase ? (
    <div className={classes.choosePaymentMethod}>{t`Choose payment method`}</div>
  ) : (
    <div className={classes.choosePaymentMethod}>{t`Choose destination of transaction`}</div>
  );

  const choosenWalletComponent = (
    <Wallet
      currency={(selectedPaymentMethod?.paymentMethod as WalletsListModel)?.asJson?.currency || ''}
      balance={amountPipe(
        (selectedPaymentMethod?.paymentMethod as WalletsListModel)?.asJson?.balance || 0,
      )}
    />
  );

  const choosenCardComponent = (
    <Card
      brand={(selectedPaymentMethod?.paymentMethod as CreditCardListModel)?.asJson?.brand || ''}
      lastDigits={
        (selectedPaymentMethod?.paymentMethod as CreditCardListModel)?.asJson?.last4 || ''
      }
      isExpired={
        (selectedPaymentMethod?.paymentMethod as CreditCardListModel)?.asJson?.isExpired || false
      }
    />
  );

  switch (true) {
    case selectedPaymentMethod && selectedPaymentMethod?.paymentType === MultiMetalPaymentType.card:
      currentPaymentComponent = choosenCardComponent;
      break;
    case selectedPaymentMethod &&
      selectedPaymentMethod?.paymentType === MultiMetalPaymentType.deposit:
      currentPaymentComponent = choosenWalletComponent;
      break;
    default:
      currentPaymentComponent = choosePaymentMethodComponent;
  }

  const mobileComponent = (
    <Drawer
      anchor={'bottom'}
      open={isOpenPaymentMethod}
      className={classes.drawer}
      onClose={() => toggleIsOpenPaymentMethod(false)}
    >
      <div className={classes.drawerContent}>
        <PaymentMethodsMenu
          onAddNewCard={onAddNewCard}
          wallets={wallets}
          cards={cards}
          isHiddenCards={isHiddenCards}
          isPurchase={isPurchase}
          onSelectPaymentMethod={handleSelectPaymentMethod}
          description={description}
          emptyStateText={emptyStateText}
        />
      </div>
      <CloseDrawerButton onClick={() => toggleIsOpenPaymentMethod(false)} />
    </Drawer>
  );

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: '#291945',
    border: '1px solid #473763',
    borderRadius: '10px',
    '&:focus-visible': {
      outline: 'none',
    },
  };

  const desktopComponent = (
    <Modal
      open={isOpenPaymentMethod}
      onClose={() => toggleIsOpenPaymentMethod(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classes.desktopModalView}>
        <Box sx={style}>
          <PaymentMethodsMenu
            onAddNewCard={onAddNewCard}
            wallets={wallets}
            cards={cards}
            isPurchase={isPurchase}
            onSelectPaymentMethod={handleSelectPaymentMethod}
            description={description}
            emptyStateText={emptyStateText}
            isHiddenCards={isHiddenCards}
          />
        </Box>
      </div>
    </Modal>
  );

  const basedComponent = isMobile ? mobileComponent : desktopComponent;

  return (
    <div
      className={`${classes.root} ${Boolean(selectedPaymentMethod) ? classes.choosen : ''}`}
      onClick={() => toggleIsOpenPaymentMethod(!isOpenPaymentMethod)}
    >
      {currentPaymentComponent}
      <div className={classes.arrowRight}>
        <img src={String(ArrowRightSvg)} />
      </div>
      {basedComponent}
    </div>
  );
};

export const SelectPaymentMethod = appWithStyles(styles)(SelectPaymentMethodComponent);
