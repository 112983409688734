import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { Loading } from '@shared/components/loading';

import { styles } from './submit-button.styles';

export type SubmitButtonProps = AppWithStyles<any> &
  ButtonProps & {
    label: string;
    isLoading?: boolean;
    disabled?: boolean;
    onSubmit: () => void;
  };

const SubmitButtonComponent: React.FC<SubmitButtonProps> = ({
  label,
  disabled,
  onSubmit,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <Button variant="outlined" disabled={true} onClick={onSubmit}>
        <Loading size={30} />
      </Button>
    );
  }
  return (
    <Button variant="contained" disabled={isLoading || disabled} onClick={onSubmit}>
      {label}
    </Button>
  );
};

export default appWithStyles(styles)(SubmitButtonComponent);
