import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

import CanceledIcon from './img/canceled.svg';
import ExecutedIcon from './img/executed.svg';
import InProgressIcon from './img/in-pogress.svg';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    root: {
      position: 'relative',
      height: '14px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50px',
      [breakpoints.up(Layout.laptop)]: {
        width: '125px',
      },
    },
    text: {
      color: '#FFF',
      fontSize: '15px',
      fontWeight: 600,
      letterSpacing: '0.075px',
      backgroundPosition: 'left !important',
      paddingLeft: '25px',
    },
    icon: {
      width: '28px',
      height: '28px',
      borderRadius: '50%',
      backgroundColor: '#372753 !important',
    },
    inProgress: {
      backgroundImage: `url(${InProgressIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    canceled: {
      background: `url(${CanceledIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    executed: {
      backgroundImage: `url(${ExecutedIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    failed: {
      backgroundImage: `url(${ExecutedIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  });
}
