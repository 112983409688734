import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';

export const styles = (theme: Theme) =>
  appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .persona-widget__iframe': {
        width: '100%',
        height: '100%',
      },
    },
  });
