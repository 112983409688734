import React from 'react';

import { IconProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import { AppSvgIcon } from '@shared/components/svg-icon';

const StyledSvgIcon = withStyles(() => ({
  root: {
    width: '18px',
    height: '9px',
    pointerEvents: 'none',
    position: 'absolute',
    top: '10px',
    right: '2px',
    fill: 'transparent',
    transition: 'transform 0.2s ease-in-out',
    transformOrigin: 'center center',
  },
}))(AppSvgIcon);

export const ArrowIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSvgIcon width="11" height="6" viewBox="0 0 11 6" fill="none" className={props.className}>
      <path
        d="M1 1L5.5 5L10 1"
        stroke="#F7F9FC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </StyledSvgIcon>
  );
};
