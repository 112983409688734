import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Button } from '@mui/material';
import { SelectedPaymentMethod } from '@shared/components/new-design/select-payment-method/selected-payment-method.type';
import { CreditCardListModel } from '@shared/models/credit-card/list-model';
import { MultiMetalPaymentType } from '@shared/models/orders/payment-method';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { amountPipe } from '@shared/pipes';
import classNames from 'classnames';

import { Card } from '../card/card';
import { Wallet } from '../wallet/wallet';
import EmptyStateIcon from './img/empty-state.svg';

import { styles } from './payment-methods-menu.styles';

export type PaymentMethodsMenuProps = AppWithStyles<any> & {
  wallets: Array<WalletsListModel>;
  cards: Array<CreditCardListModel>;
  onSelectPaymentMethod: (selectedPaymentMethod: SelectedPaymentMethod) => void;
  onAddNewCard: () => void;
  isPurchase?: boolean;
  description?: string;
  emptyStateText?: string;
  isHiddenCards?: boolean;
};

const PaymentMethodsMenuComponent: React.FC<PaymentMethodsMenuProps> = ({
  classes,
  wallets,
  cards,
  onSelectPaymentMethod,
  onAddNewCard,
  isPurchase,
  description,
  emptyStateText,
  isHiddenCards,
}) => {
  const handleClick = (
    e: React.MouseEvent<HTMLDivElement>,
    paymentType: MultiMetalPaymentType,
    walletOrCard: WalletsListModel | CreditCardListModel,
  ) => {
    e.stopPropagation();
    if (walletOrCard instanceof CreditCardListModel && walletOrCard.asJson.isExpired) {
      return;
    }
    onSelectPaymentMethod({
      paymentMethod: walletOrCard,
      paymentType,
    } as SelectedPaymentMethod);
  };

  const walletsBlock = wallets.length ? (
    <div className={classes.wallets}>
      {isHiddenCards ? <></> : <div className={classes.blockName}>{t`Your Wallets`}</div>}
      <div className={classes.walletsList}>
        {wallets.map((wallet) => {
          return (
            <div
              className={classes.paymentMethod}
              onClick={(e) => handleClick(e, MultiMetalPaymentType.deposit, wallet)}
            >
              <Wallet
                isActiveTopUpButton={false}
                currency={wallet.asJson.currency}
                balance={amountPipe(wallet.asJson.balance)}
              />
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );

  const emptyState = (
    <div className={classes.emptyState}>
      <img src={String(EmptyStateIcon)} />
      <div className={classes.emptyStateText}>
        {emptyStateText ? emptyStateText : t`It looks like You still have no payment cards`}
      </div>
    </div>
  );

  if (wallets.length === 0 && cards.length === 0) {
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          {isPurchase ? t`Payment Methods` : t`Destination of Transaction`}
        </div>
        {emptyState}
        {isHiddenCards ? (
          <></>
        ) : (
          <div className={classes.addCardButton}>
            <Button variant="outlined" onClick={onAddNewCard}>
              +{t`Add new card`}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, { [classes.long as string]: wallets.length === 0 })}>
      <div className={classes.title}>
        {isPurchase ? t`Payment Methods` : t`Destination of Transaction`}
      </div>
      {description ? (
        <div className={classes.descriptionBlock}>
          <div className={classes.description}>{description}</div>
        </div>
      ) : (
        <></>
      )}
      {isHiddenCards ? (
        <></>
      ) : (
        <div className={classes.cards}>
          {wallets.length > 0 ? <div className={classes.blockName}>{t`Debit Cards`}</div> : <></>}
          <div
            className={classNames({
              [classes.cardsList as string]: wallets.length > 0,
              [classes.cardsWithoutWallets as string]: wallets.length === 0,
            })}
          >
            {cards.length > 0 ? (
              cards.map((card) => {
                return (
                  <div
                    className={classes.paymentMethod}
                    onClick={(e) => handleClick(e, MultiMetalPaymentType.card, card)}
                  >
                    <Card
                      brand={card.asJson.brand}
                      lastDigits={
                        card.asJson.isTemporary
                          ? `${card.asJson.last4} ` + t`(not saved)`
                          : card.asJson.last4
                      }
                      isActiveMoreButton={true}
                      isExpired={card.asJson.isExpired}
                    />
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
          {wallets.length !== 0 ? (
            <div className={classes.addNewCardButton}>
              <Button variant="outlined" onClick={onAddNewCard}>
                +{t`Add new card`}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {walletsBlock}
      {wallets.length === 0 && !isHiddenCards ? (
        <div className={classes.addCardButton}>
          <Button variant="outlined" onClick={onAddNewCard}>
            +{t`Add new card`}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const PaymentMethodsMenu = appWithStyles(styles)(PaymentMethodsMenuComponent);
