import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

export const styles = (theme: Theme) =>
  appCreateStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '12px',
      background: '#1E1D36',
      paddingLeft: '22px',
      paddingRight: '25px',
      cursor: 'pointer',
      position: 'relative',
      '&$expired': {
        cursor: 'unset',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        background: 'transparent',
      },
    },
    divider: {
      color: '#5B5282',
      fontSize: '25px',
      fontStyle: 'italic',
      fontWeight: 300,
      lineHeight: '170%',
      letterSpacing: '0.3px',
      textTransform: 'uppercase',
      marginLeft: '13px',
      marginRight: '17px',
    },
    info: {
      display: 'flex',
      alignItems: 'center',
    },
    iconBlock: {
      width: '49px',
      position: 'relative',
      display: 'flex',
    },
    icon: {
      width: '100%',
      height: '100%',
      '$opacity &': {
        opacity: 0.3,
      },
    },
    opacity: {},
    cardNumberPlaceholder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '40px',
      marginRight: '14px',
    },
    digitsBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    star: {
      width: '5px',
      '& + &': {
        marginLeft: '3px',
      },
    },
    lastDigits: {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '170%',
      letterSpacing: '1px',
      '$opacity &': {
        opacity: 0.3,
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    expired: {
      background: '#1E1D27 !important',
    },
    expiredText: {
      position: 'absolute',
      bottom: '12px',
      left: '109px',
      color: '#D12F2F',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.2px',
    },
    cardNumberBlock: {
      display: 'flex',
      '$expired &': {
        paddingBottom: '10px',
      },
    },
  });
