import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export const styles = (theme: Theme) =>
  appCreateStyles({
    root: {
      padding: '20px',
      minHeight: '500px',
      [theme.breakpoints.up(Layout.laptop)]: {
        minHeight: 'unset',
      },
      '&$long': {
        padding: '20px 20px 90px 20px',
      },
    },
    long: {},
    title: {
      color: '#fff',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      marginTop: '4px',
      marginBottom: '21px',
    },
    descriptionBlock: {
      width: '100%',
      marginBottom: '34px',
      display: 'flex',
      justifyContent: 'center',
    },
    description: {
      color: '#79818F',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.4px',
      maxWidth: '282px',
    },
    wallets: {
      marginTop: '26px',
    },
    blockName: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginLeft: '12px',
      marginBottom: '16px',
    },
    item: {
      '& + &': {
        marginTop: '10px',
      },
    },
    paymentMethod: {
      height: '78px',
      '& + &': {
        marginTop: '10px',
      },
    },
    addNewCardButton: {
      height: '78px',
      marginTop: '10px',
    },
    addCardButton: {
      position: 'absolute',
      bottom: '20px',
      width: 'calc(100% - 40px)',
    },
    emptyState: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      height: '300px',
      paddingLeft: '20px',
      paddingRight: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        paddingBottom: '76px',
      },
    },
    emptyStateText: {
      color: '#79818F',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '150%',
      letterSpacing: '0.2px',
      marginTop: '17px',
    },
    cardsList: {
      maxHeight: `calc(${_var(CSS_VARIABLES.appHeight)} - 559px)`,
      overflow: 'auto',
      [theme.breakpoints.up(Layout.laptop)]: {
        height: '100%',
        maxHeight: `calc(${_var(CSS_VARIABLES.appHeight)} - 67vh)`,
      },
    },
    cardsWithoutWallets: {
      maxHeight: `calc(${_var(CSS_VARIABLES.appHeight)} - 345px)`,
      overflow: 'auto',
    },
    walletsList: {},
  });
