import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

export const styles = (theme: Theme) =>
  appCreateStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '12px',
      background: '#1E1D36',
      paddingLeft: '22px',
      paddingRight: '25px',
      cursor: 'pointer',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: 'transparent',
      },
    },
    insufficient: {
      background: '#231010;',
      border: '1px solid #B42626',
    },
    currency: {
      color: '#fff',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '124%',
      letterSpacing: '0.2px',
      [theme.breakpoints.up(Layout.laptop)]: {
        fontWeight: 700,
      },
    },
    divider: {
      color: '#5B5282',
      fontSize: '25px',
      fontStyle: 'italic',
      fontWeight: 300,
      lineHeight: '170%',
      letterSpacing: '0.3px',
      textTransform: 'uppercase',
      marginLeft: '13px',
      marginRight: '17px',
    },
    balanceTitle: {
      color: '#63608C',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '130%',
      letterSpacing: '0.3px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    balance: {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '130%',
      letterSpacing: '0.3px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    info: {
      display: 'flex',
      alignItems: 'center',
    },
    topUpBlock: {
      width: 'fit-content',
      maxHeight: '37px',
      '& .MuiButton-contained': {
        color: 'rgba(255, 255, 255, 0.50)',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '124%',
        letterSpacing: '0.2px',
        textDecoration: 'capitalize',
        borderRadius: '30px',
        background: '#363453',
        textTransform: 'uppercase',
        padding: '8px 10px',
        height: '37px',
        [theme.breakpoints.up(Layout.laptop)]: {
          color: '#63608C',
        },
      },
    },
    systemAccount: {
      color: '#FFF',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '130%',
      letterSpacing: '0.3px',
    },
  });
