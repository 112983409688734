import React from 'react';

import { appInject } from '@core/di/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Button } from '@mui/material';
import { DI_TOKENS } from '@shared/constants/di';
import { ProductType } from '@shared/models/products/product-model';
import { IProductsVM } from '@shared/types/products-vm';
import classNames from 'classnames';

import { styles } from './wallet.styles';

export type WalletProps = AppWithStyles<any> & {
  currency: string;
  balance: string;
  isActiveTopUpButton?: boolean;
  isInsufficient?: boolean;
  isDetailsView?: boolean;
};

const WalletComponent: React.FC<WalletProps> = ({
  classes,
  currency,
  balance,
  isActiveTopUpButton,
  isInsufficient,
  isDetailsView,
}) => {
  const productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  const displayedCurrency =
    productsVM.getProductTypeByCurrency(currency) === ProductType.SMART_COIN ? currency : '$';

  const balanceComponent = isInsufficient ? (
    <div className={classes.balance}>
      {balance} ({t`insufficient`})
    </div>
  ) : (
    <div className={classes.balance}>
      {displayedCurrency} {balance}
    </div>
  );
  const rootClass = isInsufficient
    ? classNames(classes.root, classes.insufficient, 'wallet-root')
    : classNames(classes.root, 'wallet-root');
  return (
    <div className={rootClass}>
      <div className={classes.info}>
        <div className={classes.currency}>{currency}</div>
        <div className={classes.divider}>\</div>
        {isDetailsView ? (
          <div className={classes.balanceBlock}>
            <div className={classes.systemAccount}>{t`System account`}</div>
          </div>
        ) : (
          <div className={classes.balanceBlock}>
            <div className={classes.balanceTitle}>{t`Balance`}</div>
            {balanceComponent}
          </div>
        )}
      </div>
      {isActiveTopUpButton ? (
        <div className={classes.topUpBlock}>
          <Button variant="contained" onClick={() => {}}>
            {t`Top up`}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const Wallet = appWithStyles(styles)(WalletComponent);
