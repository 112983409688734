import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import {
  KYCCredentialsModel,
  KYCCredentialsQuery,
} from '@shared/models/users/kyc-credentials-model';
import { IAuthService } from '@shared/types/auth-service';
import { IUsersService } from '@shared/types/users-service';

export class PersonaWidgetViewModel {
  usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  authService = appInject<IAuthService>(DI_TOKENS.authService);

  private credentials:
    | KYCCredentialsModel
    | null
    | { errors: Array<{ key: string; text: string }> };
  constructor() {}

  get inquiryId() {
    if (this.credentials instanceof KYCCredentialsModel) {
      return this.credentials.asJson.kycUserId;
    } else {
      return '';
    }
  }

  get sessionToken() {
    if (this.credentials instanceof KYCCredentialsModel) {
      return this.credentials.asJson.token;
    } else {
      return '';
    }
  }

  private generateStorageKey() {
    return `kyc:${this.authService.userId}`;
  }

  private getCredentialsFromStorage(): KYCCredentialsModel | null {
    const credentialsQuery: KYCCredentialsQuery | null =
      JSON.parse(localStorage.getItem(this.generateStorageKey()) || '{}').credentials || null;
    return credentialsQuery ? new KYCCredentialsModel(credentialsQuery) : null;
  }

  private saveCredentialsToStorage(credentials: KYCCredentialsModel) {
    localStorage.setItem(
      this.generateStorageKey(),
      JSON.stringify({
        credentials: credentials,
        timestamp: Date.now(),
      }),
    );
  }

  removeCredentialsFromStorage() {
    localStorage.removeItem(this.generateStorageKey());
  }

  async downloadKYCCredentials() {
    const credentials = this.getCredentialsFromStorage();
    if (credentials) {
      this.credentials = credentials;
      return null;
    }

    const response = await this.usersService.getKYCCredentials();

    if (response.errors) {
      return response;
    } else if (response.asJson.kycUserId || response.asJson.token) {
      this.saveCredentialsToStorage(response);
      this.credentials = response;
      return null;
    } else {
      this.credentials = response;
      return null;
    }
  }

  async getMyUserDetails() {
    return await this.usersService.getMyUserDetails();
  }

  async submitKYC() {
    this.removeCredentialsFromStorage();
    await this.usersService.submitKYCCredentials();
  }
}
